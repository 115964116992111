import type { ZoneOptions } from '~/services/types'
import type { MapZone } from '~/types'
import type { PostgrestError } from '@supabase/postgrest-js'
import { getGeoPolygonCenter } from '~/utils/geoHelpers'
import { SupabaseClient } from '@supabase/supabase-js'

export declare abstract class IZonesService {
  abstract getAll(options?: ZoneOptions): Promise<MapZone[]>

  abstract getGroupAll(groupId: string): Promise<MapZone[]>

  abstract get(zoneId: string): Promise<MapZone | null>

  abstract update(zoneId: string, data: any): Promise<MapZone>

  abstract delete(zoneId: string): Promise<void>

  abstract create(model: any): Promise<MapZone>
}

export class ZonesServiceImpl implements IZonesService {
  private readonly supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }

  async delete(zoneId: string) {
    const { error } = await this.supabase.from('zone').delete().eq('id', zoneId)
    if (error) throw error
  }

  async get(zoneId: string): Promise<MapZone | null> {
    const response = await this.supabase
      .from('zone')
      .select('*')
      .eq('id', zoneId)
      .limit(1)
      .single()
    return response.data as MapZone | null
  }

  async getAll(options?: ZoneOptions): Promise<MapZone[]> {
    let query = this.supabase.from('zone').select(options?.customSelect ?? '*')

    if (options?.global === true) {
      query = query.is('groupId', null)
    }

    const { data, error } = await query
      .order('groupId', { ascending: false })
      .order('name', { ascending: true })

    if (error) throw error

    return data as unknown as MapZone[]
  }

  async getGroupAll(groupId: string): Promise<MapZone[]> {
    const { data, error } = await this.supabase
      .from('zone')
      .select('*')
      .eq('groupId', groupId)
      .order('name', { ascending: true })

    if (error) throw error

    return data as unknown as MapZone[]
  }

  async update(zoneId: string, data: any): Promise<MapZone> {
    let updateDTO: any = {}
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key]
    })

    if (updateDTO.polygon) {
      updateDTO.center = getGeoPolygonCenter(updateDTO.polygon.coordinates)
    }

    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from('zone')
      .update(updateDTO as never)
      .eq('id', zoneId)
      .select('*, group:groupId(*)')
      .single()

    if (updateModelError) throw updateModelError

    return updateResponse
  }

  async create(model: any): Promise<MapZone> {
    let updateDTO: any = {}
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key]
    })

    if (updateDTO.polygon) {
      updateDTO.center = getGeoPolygonCenter(updateDTO.polygon.coordinates)
    }

    const {
      data: insertResponse,
      error: insertModelError,
    }: any & PostgrestError = await this.supabase
      .from('zone')
      .insert([updateDTO as never])
      .select('*, group:groupId(*)')
      .single()

    if (insertModelError) throw insertModelError

    return insertResponse
  }
}
