import type { Asset, AssetType } from '~/types'
import type { PostgrestError } from '@supabase/postgrest-js'
import { SupabaseClient } from '@supabase/supabase-js'

export declare abstract class IAssetService {
  abstract getAll(groupId?: string, includeType?: boolean): Promise<Asset[]>

  abstract get(assetId: string): Promise<Asset | null>

  abstract update(assetId: string, data: any): Promise<Asset>

  delete(assetId: string): Promise<void>

  abstract create(model: any): Promise<Asset>

  abstract getAssetTypes(): Promise<AssetType[]>
}

export class AssetServiceImpl implements IAssetService {
  private readonly supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }

  async delete(assetId: string) {
    const { error } = await this.supabase
      .from('asset')
      .delete()
      .eq('id', assetId)
    if (error) throw error
  }

  async get(assetId: string): Promise<Asset | null> {
    const response = await this.supabase
      .from('asset')
      .select('*')
      .eq('id', assetId)
      .limit(1)
      .single()
    return response.data as Asset | null
  }

  async getAll(
    groupId?: string,
    includeType: boolean = false,
  ): Promise<Asset[]> {
    const query = includeType ? '*, type:assetType(name, icon)' : '*'

    const { data, error } = await this.supabase
      .from('asset')
      .select(query)
      .eq('groupId', groupId ? [groupId] : [])
      .order('name', { ascending: true })

    if (error) throw error

    return data as unknown as Asset[]
  }

  async update(assetId: string, data: any): Promise<Asset> {
    let updateDTO: any = {}
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key]
    })

    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from('asset')
      .update(updateDTO as never)
      .eq('id', assetId)
      .select('*, type:assetType(name, icon)')

    if (updateModelError) {
      throw updateModelError
    }

    return updateResponse[0]
  }

  async create(model: any): Promise<Asset> {
    let updateDTO: any = {}
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key]
    })

    const {
      data: insertResponse,
      error: insertModelError,
    }: any & PostgrestError = await this.supabase
      .from('asset')
      .insert([updateDTO as never])
      .select('*, type:assetType(name, icon)')

    if (insertModelError) throw insertModelError

    return insertResponse[0]
  }

  async getAssetTypes(): Promise<AssetType[]> {
    const { data: getResult, error: getError } = await this.supabase
      .from('assetType')
      .select()
      .order('name', { ascending: true })

    if (getError) throw getError

    return getResult as AssetType[]
  }
}
