import type {
  IncidentSourceEntry,
  InfoSource,
  SourceCategory,
  SourceSubCategory,
  SourceCategoryCreateDTO,
  SourceSubCategoryCreateDTO,
} from '~/types'
import { SupabaseClient } from '@supabase/supabase-js'

export declare abstract class ISourceService {
  abstract getAll(): Promise<InfoSource[]>

  abstract get(sourceId: string): Promise<InfoSource | null>

  abstract create(model: any): Promise<InfoSource>

  abstract update(sourceId: string, model: any): Promise<InfoSource>

  abstract delete(sourceId: string): Promise<void>

  abstract getEntries(sourceId: string): Promise<IncidentSourceEntry[]>

  abstract getCategories(): Promise<SourceCategory[]>

  abstract getCategory(categoryId: string): Promise<SourceCategory | null>

  abstract createCategory(
    model: SourceCategoryCreateDTO,
  ): Promise<SourceCategory>

  abstract updateCategory(
    categoryId: string,
    model: any,
  ): Promise<SourceCategory>

  abstract deleteCategory(categoryId: string): Promise<void>

  abstract getCategorySources(categoryId: string): Promise<InfoSource[]>

  abstract getSubCategories(): Promise<SourceSubCategory[]>

  abstract getSubCategory(
    subCategoryId: string,
  ): Promise<SourceSubCategory | null>

  abstract createSubCategory(
    model: SourceSubCategoryCreateDTO,
  ): Promise<SourceSubCategory>

  abstract updateSubCategory(
    subCategoryId: string,
    model: any,
  ): Promise<SourceSubCategory>

  abstract deleteSubCategory(subCategoryId: string): Promise<void>

  abstract getSubCategorySources(subCategoryId: string): Promise<InfoSource[]>
}

export class SourceServiceImpl implements ISourceService {
  private readonly supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }

  async getAll(): Promise<InfoSource[]> {
    const { data: sources, error: requestError } = await this.supabase
      .from('infoSource')
      .select('*, subCategory:sourceSubCategory(*, category:sourceCategory(*))')
      .order('name', { ascending: true })

    if (requestError) throw requestError
    return sources as InfoSource[]
  }

  async get(sourceId: string): Promise<InfoSource | null> {
    const { data: source, error: requestError } = await this.supabase
      .from('infoSource')
      .select('*, subCategory:sourceSubCategory(*, category:sourceCategory(*))')
      .eq('id', sourceId)
      .single()

    if (requestError) throw requestError

    return source as InfoSource
  }

  async create(model: any): Promise<InfoSource> {
    let createDTO: any = {}
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) createDTO[key] = model[key]
    })

    const { data: insertResponse, error: insertModelError } =
      await this.supabase
        .from('infoSource')
        .insert([createDTO as never])
        .select(
          '*, subCategory:sourceSubCategory(*, category:sourceCategory(*))',
        )

    if (insertModelError) throw insertModelError

    return insertResponse[0] as InfoSource
  }

  async update(sourceId: string, model: any): Promise<InfoSource> {
    let updateDTO: any = {}
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key]
    })

    const { data: updateResponse, error: updateError } = await this.supabase
      .from('infoSource')
      .update(updateDTO as never)
      .eq('id', sourceId)
      .select('*, subCategory:sourceSubCategory(*, category:sourceCategory(*))')

    if (updateError) throw updateError

    return updateResponse[0] as InfoSource
  }

  async delete(sourceId: string): Promise<void> {
    const { error } = await this.supabase
      .from('infoSource')
      .delete()
      .eq('id', sourceId)

    if (error) throw error
  }

  async getEntries(sourceId: string): Promise<IncidentSourceEntry[]> {
    const { data: entries, error: requestError } = await this.supabase
      .from('incidentSourceEntry')
      .select('*')
      .eq('sourceId', sourceId)
      .order('date', { ascending: false })

    if (requestError) throw requestError

    return entries as IncidentSourceEntry[]
  }

  async getCategories(): Promise<SourceCategory[]> {
    const { data: categories, error: requestError } = await this.supabase
      .from('sourceCategory')
      .select('*, subCategories:sourceSubCategory(*, sources:infoSource(*))')
      .order('name', { ascending: true })
      .order('name', { ascending: true, foreignTable: 'sourceSubCategory' })

    if (requestError) throw requestError

    return categories as SourceCategory[]
  }

  async getCategory(categoryId: string): Promise<SourceCategory | null> {
    const { data: category, error: requestError } = await this.supabase
      .from('sourceCategory')
      .select('*')
      .eq('id', categoryId)
      .single()

    if (requestError) throw requestError

    return category as SourceCategory
  }

  async createCategory(
    model: SourceCategoryCreateDTO,
  ): Promise<SourceCategory> {
    const { data: insertResponse, error: insertModelError } =
      await this.supabase
        .from('sourceCategory')
        .insert([model as never])
        .select('*')
        .single()

    if (insertModelError) throw insertModelError
    return insertResponse as SourceCategory
  }

  async updateCategory(
    categoryId: string,
    model: any,
  ): Promise<SourceCategory> {
    let updateDTO: any = {}
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key]
    })

    const { data: updateResponse, error: updateError } = await this.supabase
      .from('sourceCategory')
      .update(updateDTO as never)
      .eq('id', categoryId)
      .select('*')

    if (updateError) throw updateError

    return updateResponse[0] as SourceCategory
  }

  async deleteCategory(categoryId: string): Promise<void> {
    const { error } = await this.supabase
      .from('sourceCategory')
      .delete()
      .eq('id', categoryId)

    if (error) throw error
  }

  async getCategorySources(categoryId: string): Promise<InfoSource[]> {
    const { data: sources, error: requestError } = await this.supabase
      .from('infoSource')
      .select('*')
      .eq('categoryId', categoryId)
      .order('name', { ascending: true })

    if (requestError) throw requestError

    return sources as InfoSource[]
  }

  async getSubCategories(): Promise<SourceSubCategory[]> {
    const { data: subCategories, error: requestError } = await this.supabase
      .from('sourceSubCategory')
      .select('*, category:sourceCategory(*)')
      .order('name', { ascending: true })

    if (requestError) throw requestError

    return subCategories as SourceSubCategory[]
  }

  async getSubCategory(
    subCategoryId: string,
  ): Promise<SourceSubCategory | null> {
    const { data: subCategory, error: requestError } = await this.supabase
      .from('sourceSubCategory')
      .select('*')
      .eq('id', subCategoryId)
      .single()

    if (requestError) throw requestError

    return subCategory as SourceSubCategory
  }

  async createSubCategory(
    model: SourceSubCategoryCreateDTO,
  ): Promise<SourceSubCategory> {
    const { data: insertResponse, error: insertModelError } =
      await this.supabase
        .from('sourceSubCategory')
        .insert([model as never])
        .select('*')
        .single()

    if (insertModelError) throw insertModelError
    return insertResponse as SourceSubCategory
  }

  async updateSubCategory(
    subCategoryId: string,
    model: any,
  ): Promise<SourceSubCategory> {
    let updateDTO: any = {}
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key]
    })

    const { data: updateResponse, error: updateError } = await this.supabase
      .from('sourceSubCategory')
      .update(updateDTO as never)
      .eq('id', subCategoryId)
      .select('*')

    if (updateError) throw updateError

    return updateResponse[0] as SourceSubCategory
  }

  async deleteSubCategory(subCategoryId: string): Promise<void> {
    const { error } = await this.supabase
      .from('sourceSubCategory')
      .delete()
      .eq('id', subCategoryId)

    if (error) throw error
  }

  async getSubCategorySources(subCategoryId: string): Promise<InfoSource[]> {
    const { data: sources, error: requestError } = await this.supabase
      .from('infoSource')
      .select('*')
      .eq('subCategoryId', subCategoryId)
      .order('name', { ascending: true })

    if (requestError) throw requestError

    return sources as InfoSource[]
  }
}
