import revive_payload_client_TaHINVHZ3c from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@types+node@22.13.10_eslint@8.57.0_rollup@4.36.0_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Oho7jXK6Yw from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@types+node@22.13.10_eslint@8.57.0_rollup@4.36.0_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_oSG8lv4IRy from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@types+node@22.13.10_eslint@8.57.0_rollup@4.36.0_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_r8PKFFb8Nx from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.5.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_PjoHDpiPRT from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@types+node@22.13.10_eslint@8.57.0_rollup@4.36.0_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_quyQdua4hg from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@types+node@22.13.10_eslint@8.57.0_rollup@4.36.0_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_a0WJQYPlBA from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@types+node@22.13.10_eslint@8.57.0_rollup@4.36.0_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6Yug5mS9Rn from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@types+node@22.13.10_eslint@8.57.0_rollup@4.36.0_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_28Cj69pbKW from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.4.5_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.app/.nuxt/components.plugin.mjs";
import prefetch_client_eY8qBDvfTH from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.1_@types+node@22.13.10_eslint@8.57.0_rollup@4.36.0_sass@1.77.8_typescript@5.4.5_vue-tsc@2.0.12/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_6oLCTF8W5D from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_Mn7I14lJO0 from "/opt/render/project/src/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_vue@3.5.13/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import i18n_VPmU2QAJM2 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.36.0_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import auth_redirect_emRxcw23nb from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.5.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import plugin_3VS5yuwEw3 from "/opt/render/project/src/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.4.11_pinia@2.3.1/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import directives_yBPzJQtdia from "/opt/render/project/src/layers/tairo/plugins/directives.ts";
import _00_logging_client_rLDKabzlzG from "/opt/render/project/src/.app/plugins/00.logging.client.ts";
import dx_client_pUj1dsd230 from "/opt/render/project/src/.app/plugins/dx.client.ts";
import eventbus_client_hXjVpwcj1U from "/opt/render/project/src/.app/plugins/eventbus.client.ts";
import service_workers_client_oduUtRk6M5 from "/opt/render/project/src/.app/plugins/service-workers.client.ts";
import services_client_NIWiCMi26b from "/opt/render/project/src/.app/plugins/services.client.ts";
export default [
  revive_payload_client_TaHINVHZ3c,
  unhead_Oho7jXK6Yw,
  router_oSG8lv4IRy,
  supabase_client_r8PKFFb8Nx,
  payload_client_PjoHDpiPRT,
  navigation_repaint_client_quyQdua4hg,
  check_outdated_build_client_a0WJQYPlBA,
  chunk_reload_client_6Yug5mS9Rn,
  plugin_vue3_28Cj69pbKW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_eY8qBDvfTH,
  plugin_client_6oLCTF8W5D,
  plugin_Mn7I14lJO0,
  i18n_VPmU2QAJM2,
  auth_redirect_emRxcw23nb,
  plugin_3VS5yuwEw3,
  directives_yBPzJQtdia,
  _00_logging_client_rLDKabzlzG,
  dx_client_pUj1dsd230,
  eventbus_client_hXjVpwcj1U,
  service_workers_client_oduUtRk6M5,
  services_client_NIWiCMi26b
]