import type { SafeZone } from '~/types'
import type { PostgrestError } from '@supabase/postgrest-js'
import { getGeoPolygonCenter } from '~/utils/geoHelpers'
import { SupabaseClient } from '@supabase/supabase-js'

export declare abstract class ISafeZonesService {
  abstract getAll(customSelect?: string): Promise<SafeZone[]>

  abstract get(zoneId: string): Promise<SafeZone | null>

  abstract update(zoneId: string, data: any): Promise<SafeZone>

  abstract delete(zoneId: string): Promise<void>

  abstract create(model: any): Promise<SafeZone>
}

export class SafeZonesServiceImpl implements ISafeZonesService {
  private readonly supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }

  async delete(zoneId: string) {
    const { error } = await this.supabase
      .from('safeZone')
      .delete()
      .eq('id', zoneId)
    if (error) throw error
  }

  async get(zoneId: string): Promise<SafeZone | null> {
    const response = await this.supabase
      .from('safeZone')
      .select('*')
      .eq('id', zoneId)
      .limit(1)
      .single()
    return response.data as SafeZone | null
  }

  async getAll(customSelect?: string): Promise<SafeZone[]> {
    const { data, error } = await this.supabase
      .from('safeZone')
      .select(customSelect ? customSelect : '*')
      .order('name', { ascending: true })

    if (error) throw error

    return data as unknown as SafeZone[]
  }

  async update(zoneId: string, data: any): Promise<SafeZone> {
    let updateDTO: any = {}
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined) updateDTO[key] = data[key]
    })

    if (updateDTO.polygon && !updateDTO.center) {
      updateDTO.center = getGeoPolygonCenter(updateDTO.polygon.coordinates)
    }

    const {
      data: updateResponse,
      error: updateModelError,
    }: any & PostgrestError = await this.supabase
      .from('safeZone')
      .update(updateDTO as never)
      .eq('id', zoneId)
      .select('*')

    if (updateModelError) throw updateModelError

    return updateResponse[0]
  }

  async create(model: any): Promise<SafeZone> {
    let updateDTO: any = {}
    Object.keys(model).forEach((key) => {
      if (model[key] !== undefined) updateDTO[key] = model[key]
    })

    if (updateDTO.polygon && !updateDTO.center) {
      updateDTO.center = getGeoPolygonCenter(updateDTO.polygon.coordinates)
    }

    const {
      data: insertResponse,
      error: insertModelError,
    }: any & PostgrestError = await this.supabase
      .from('safeZone')
      .insert([updateDTO as never])
      .select()

    if (insertModelError) throw insertModelError

    return insertResponse[0]
  }
}
